import { useAuth0 } from "@auth0/auth0-react";
import React from 'react';
import Button from '@mui/material/Button';
import { TextField, MenuItem, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import * as EmailValidator from 'email-validator';
import * as api from '../api/api'

import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const SuccessModal = ({ isOpen, onClose, Status, Msg }) => {
  // console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      h4Msg = "Success!"
      pMsg = Msg
      break;
    case 'error':
      h4Msg = "Failed!"
      if (Msg === '' || Msg === undefined || Msg === null) {
        pMsg = "Response from the server is not as expected."
      } else {
        pMsg =  Msg
      }
      break;
    case 'unauth':
        h4Msg = "Authorization Failed!"
        pMsg = Msg
        break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
          <h4 className="popup-title">{h4Msg}</h4>
          <p className="success-message">{pMsg}</p>
          <div className="button-container">
            <button onClick={onClose}>Close</button>
          </div>
      </Popup>
    );
};

export default function InviteDialog({ setState, user, org}) {
  // console.log('InviteDialog ...' , user)
  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState({
    error: false,
    helperText: "",
  })
  const [errorRole, setErrorRole] = React.useState({
    error: false,
    helperText: "",
  })

  const [errorName, setErrorName] = React.useState({
    error: false,
    helperText: "",
  })

  const [submitMsg, setSubmitMsg] = React.useState('');
  const [Msg, setMsg] = React.useState('');
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [nameFilled, setNameFilled] = React.useState(false);
  const [emailFilled, setEmailFilled] = React.useState(false);
  const [roleFilled, setRoleFilled] = React.useState(false);
  const [domains, setDomains] = React.useState([]);
  const [newDomain, setNewDomain] = React.useState('');
  const [domainupdate, setDomainUpdate] = React.useState(false);
  let roles = ["pooladmin", "admin", "readonly"];

  if (user.role === 'admin') {
    roles = ["admin", "readonly"];
  }

  const addDomains = (newDomains) => {
    setDomains((prevDomains) => {
      const allDomains = new Set([...prevDomains, ...newDomains]);
      return Array.from(allDomains);
    });
  };

  function validateDomain(domain) {
    // Ensure the last character is not a dot and it contains only allowed characters
    if (domain[domain.length - 1] !== '.' && /^[a-zA-Z0-9.]+$/.test(domain)) {
      return domain;
    }
    return null;
  }

/*
 * Extract domain name from Email
 */
function extractDomainFromEmail(email) {
  const domainMatch = email.match(/@([a-zA-Z0-9.]+)$/);
  if (domainMatch) {
    const domain = domainMatch[1];
    // Ensure the last character is not a dot and it contains only allowed characters
    if (domain[domain.length - 1] !== '.' && /^[a-zA-Z0-9.]+$/.test(domain)) {
      return domain;
    }
  }
  return null;
}

  React.useEffect(() => {
    if (org !== null && org.hasOwnProperty('domains') && org.domains.length > 0) {
      // console.log("org.domains", org.domains )
      addDomains(org.domains);
      setDomains(org.domains);
    }
  }, [org]);

  const handleDeleteDomain = (domainToDelete) => {
    setDomains((prevDomains) => prevDomains.filter((domain) => domain !== domainToDelete));
    setDomainUpdate(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9._@]+$/;
    return pattern.test(email);
  }

  const checkEmail = (value) => {
    const valid = isValidEmail(value)
    if (valid) {
      const valid1 = EmailValidator.validate(value);
      if (!valid1) {
        setError({ error: true, helperText: "Invalid email" });
      } else {
        setError({ error: false, helperText: "" });
      }
      return valid1
    } else {
      setError({ error: true, helperText: "Invalid email" });
    }
    return valid
  }

  const checkRole = (value) => {
    if (value == 'true' || value == 'false') {
      setErrorRole({ error: false, helperText: "" });
      return true
    } else {
      setErrorRole({ error: true, helperText: "Invalid role value (allowed true or false)" });
      return false
    }
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    // console.log(value)
    checkEmail(value)
    setEmail(value)
    setEmailFilled(value.trim() !== '');
  }

  const handleInputChangeName = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setName(value);
    // Check if the input is not empty
    setNameFilled(value.trim() !== '');
  }

  const handleInputChangeRole = (event) => {
    // const target = event.target;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const inputValue = event.target.value.toLowerCase();
    // checkRole(inputValue)
    setRole(inputValue)
    setRoleFilled(inputValue.trim() === "pooladmin" || inputValue.trim() === "admin" || inputValue.trim() === "readonly");
  };


  const handleInputChangePhone = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    //console.log(value)
    setPhone(value)
  }

  const handleInputChangeAddress = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // console.log(value)
    setAddress(value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddDomain = () => {
    if (newDomain.trim() !== '') {
      setDomains((prevDomains) => [...prevDomains, newDomain.trim()]);
      setNewDomain('');
    }
  };

  const submitHandler = async (event) => {
    if (error.error) return

    const fn = async () => {
      var localrole = role.toLowerCase();
      if (role.toLowerCase() === "readonly") {
        localrole = "user"
      }
      const token = await getAccessTokenSilently();
      let data = await api.PostInvite(token, { email: email, name: name, role: localrole, phone: phone, address: address });
      if (data === undefined || data === null) {
        setMsg("Failed to invite user. Please check whether domain is whitelisted")
        setSubmitMsg("error")
        setSuccessModalOpen(true)
      } else if (data.status === 'ok') {
        setMsg(data.msg)
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
      } else {
        if (data.hasOwnProperty('errorCode') && data.errorCode === 1009) {
          setMsg(data.msg)
          setSubmitMsg("unauth");
          setSuccessModalOpen(true);
        } else {
          setMsg(data.msg)
          setSubmitMsg("error");
          setSuccessModalOpen(true);
        }
      }
      setState((prevState) => !prevState)
      setOpen(false);
    }
    fn()
  }

  const submitHandlerDomains = async (event) => {
    const first_domain = validateDomain(newDomain)
    if (newDomain.trim() !== '' && first_domain === null) {
      setMsg("Please enter the valid domain name. Allowed characters are a-z, A-Z, 0-9 and '.' only.")
      setSubmitMsg("error");
      setSuccessModalOpen(true);
      setState((prevState) => !prevState)
      setOpen(false);
      setNewDomain('');
      return
    }
    if ((org.hasOwnProperty('owner') === false) || (org.owner === null) ||
        (org.owner === '') || (org.owner === undefined)) {
      setMsg("Owner email is not set. Please contact the administrator.")
      setSubmitMsg("error");
      setSuccessModalOpen(true);
      setState((prevState) => !prevState)
      setOpen(false);
      setNewDomain('');
      return
    }
    const ownerDomain = extractDomainFromEmail(org.owner);
    if (ownerDomain !== null && ownerDomain === newDomain.trim()) {
      setMsg("Owner domain cannot be added to the whitelist")
      setSubmitMsg("error");
      setSuccessModalOpen(true);
      setState((prevState) => !prevState)
      setOpen(false);
      setNewDomain('');
      return
    }
    if (newDomain.trim() !== '') {
      setDomains((prevDomains) => {
        const allDomains = new Set([...prevDomains, newDomain.trim()]);
        return Array.from(allDomains);
      });
    }
    let alldomains = domains;
    if (newDomain.trim() !== '') {
      const allDomains = new Set([...domains, newDomain.trim()]);
      alldomains = Array.from(allDomains);
    }
    console.log('Current domain list', JSON.stringify(domains))
    console.log('New Domain', newDomain.trim())
    console.log('Final domain list', JSON.stringify(alldomains))
    if (alldomains.length > 5) {
      setMsg("Maximum 5 domains can be added to the whitelist")
      setSubmitMsg("error");
      setSuccessModalOpen(true);
      setState((prevState) => !prevState)
      setOpen(false);
      setNewDomain('');
      return
    }
    const fn = async () => {
      const token = await getAccessTokenSilently();
      let data = await api.PostDomains(token, alldomains);
      if (data.status === 'ok') {
        setMsg(data.msg)
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
      } else {
        if (data.hasOwnProperty('errorCode') && data.errorCode === 1009) {
          setMsg(data.msg)
          setSubmitMsg("unauth");
          setSuccessModalOpen(true);
        } else {
          setMsg(data.msg)
          setSubmitMsg("error");
          setSuccessModalOpen(true);
        }
      }
      setState((prevState) => !prevState)
      setOpen(false);
      setNewDomain('');
    }
    fn()
  }

  if (org === null) {
    return (
      <div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Invite Users
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Invite</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To invite users, please enter their email address here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              value={email}
              fullWidth
              variant="standard"
              onChange={handleInputChange}
              helperText={error.helperText}
              error={error.error}
            />
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="name"
              value={name}
              fullWidth
              variant="standard"
              onChange={handleInputChangeName}
              error={errorName.error}
              helperText={errorName.helperText}
            />
            <TextField
              autoFocus
              margin="dense"
              name="role"
              label="Role"
              select
              value={role}
              fullWidth
              variant="standard"
              onChange={handleInputChangeRole}
              helperText={errorRole.helperText}
              error={errorRole.error}
            >
              {roles.map((roleItem) => (
                <MenuItem key={roleItem} value={roleItem}>
                  {roleItem.charAt(0).toUpperCase() + roleItem.slice(1)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              autoFocus
              margin="dense"
              name="phone"
              label="Phone Number (optional)"
              type="phone"
              value={phone}
              fullWidth
              variant="standard"
              onChange={handleInputChangePhone}
            />
            <TextField
              autoFocus
              margin="dense"
              name="address"
              label="Address (optional)"
              type="address"
              value={address}
              fullWidth
              variant="standard"
              onChange={handleInputChangeAddress}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Back</Button>
            <Button onClick={submitHandler} disabled={!nameFilled || !emailFilled || !roleFilled} >Invite</Button>
          </DialogActions>
        </Dialog>
        <div>
            <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} Msg={Msg}/>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Button variant="contained" onClick={handleClickOpen}>
          Whitelist Domains
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Whitelist Domains</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Whitelist domains to allow users to sign up with their email addresses from these domains
            </DialogContentText>
            <TextField
          label="Enter Domain"
          value={newDomain}
          onChange={(e) => setNewDomain(e.target.value)}
          fullWidth
        />
        {domains.map((domain, index) => (
          <MenuItem key={index}>
            {domain}
            <IconButton onClick={() => handleDeleteDomain(domain)} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </MenuItem>
        ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Back</Button>
            <Button onClick={() => { handleAddDomain(); submitHandlerDomains(); }}>Update</Button>
          </DialogActions>
        </Dialog>
        <div>
            <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} Msg={Msg}/>
        </div>
      </div>
    );
  }
}
