import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Skeleton } from "@mui/material";
import {
  Box,
  FormControl,
  Grid,
  Select,
  Typography,
  Container,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BodyLarge,
  H7TitleLarge,
  H8TitleMedium,
} from "../components/StyledComponents/Typography/Typography.tsx";
import {
  FetchDashboardStatistics,
  GetAllDeviceGroupsUseQuery,
  GetUserUseQuery,
} from "../api/api";

import MenuItem from "@mui/material/MenuItem";
import LineChart from "./LineChart/LineChart";
import { env } from "../env";
import PageLoading from "../components/pageloading";

import { apiBodyConstructorForDashboard } from "../api/apiHelpers";
import useAppContextProvider from "../AppContext/useAppContextProvider";
import { useQuery } from "@tanstack/react-query";
import { generateConfigForGraphs, generateDataForGraphs } from "./utils/utils";
import AuradineDataNotFoundState from "../components/StyledComponents/EmptyStates/AuradineDataNotFoundState.tsx";
import AuradineErrorState from "../components/StyledComponents/EmptyStates/AuradineErrorState.tsx";
import Footer from "../components/footer";

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log("status is", Status);
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      break;
    case "fetchfailed":
      h4Msg = "Fetch Failed!";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
    case "access_denied":
      h4Msg = "Email Validation!";
      pMsg =
        "Please check your email to verify your account and click on login";
      break;
    case "consent_required":
      h4Msg = "Consent Required!";
      pMsg = "Please click on Login button";
      break;
    case "nouser":
      h4Msg = "Authentication Failed!";
      pMsg =
        "User is not part of the Organization. Please contact your Administrator";
      break;
    case "notoken":
        h4Msg = "Authentication Failed!";
        pMsg =
          "Not able to get the token. Please contact your Administrator";
        break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
  }
  if (Status !== "ok") {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

let validUser = -1;

export const ChartCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid #C4C5D6",
  // paddingLeft: theme.spacing(6),
  // paddingRight: theme.spacing(6),
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(4),
  borderRadius: "1rem",
  textAlign: "center",
  width: "100%",
  height: "100%",
}));
export const MinimizedChartCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid #C4C5D6",
  // paddingLeft: theme.spacing(6),
  // paddingRight: theme.spacing(6),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderRadius: "1rem",
  textAlign: "center",
  width: "100%",
  height: "100%",
}));

export const DashboardTitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: "1.5rem",
  fontWeight: 400,
  color: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LineChartTitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: "1.125rem",
  fontWeight: 400,
  color: "#191c1d",
}));

export const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

const Home = () => {
  const [submitMsg, setSubmitMsg] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  /*ToDo: We encountered an issue where the navbar designed by Diana has a maximum height of 48px, while the current navbar has a height of 60px. This discrepancy caused the content to overflow and require scrolling when placed as specified in Figma. To address this, we made some adjustments to the dimensions of a few components.
The filters were given a maximum height of 2rem, and the container housing them was adjusted accordingly.
The expanded graph, originally intended to have a height of 368px, was reduced to 328px to accommodate all graphs on one page without causing overflow or cutting off content.
The smaller graphs were kept at the exact heights specified in the design. */
  const {
    selectedValue,
    utcTimeStartString,
    utcTimeEndString,
    binSize,
    calculateDateTimeValues,
    timeFilterValues: timeRanges,
    selectedGroup,
    setSelectedGroup,
  } = useAppContextProvider();
  const [authToken, setAuthToken] = useState("");

  const [isExpandedGraphsModeActivated, setIsExpandedGraphsModeActivated] =
    useState(false);

  const [expandedChartIndex, setExpandedChartIndex] = useState(-1);
  const [expandedChart, setExpandedChart] = useState([]);
  const [otherCharts, setOtherCharts] = useState([]);

  const { isLoading, getAccessTokenSilently, user } = useAuth0();

  const retrieveAccessToken = useCallback(async () => {
    try {
      const result = await getAccessTokenSilently();
      if (result !== "") {
        setAuthToken(result);
        const response = await fetch(
          env.APIPath.user,
          {
              headers: {
                  Authorization: `Bearer ${result}`,
              }
          }
        );
        const data = await response.json();
        if (data.status === "ok") {
          console.log("User is valid");
          validUser = 0
          setSubmitMsg("ok");
          setSuccessModalOpen(true);
        } else {
          if (data.errorCode === 1007) {
            validUser = 1
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg("fetchfailed");
            setSuccessModalOpen(true);
          }
        }
      } else {
        validUser = 1
        setSubmitMsg("notoken");
        setSuccessModalOpen(true);
      }
    } catch (error) {
      console.error(" Error retrieving access token", error);
      console.log('Error message', error.error, JSON.stringify(error));
      if (error.error !== "login_required") {
          validUser = 1
          setSubmitMsg(error.error);
          setSuccessModalOpen(true);
      }
    }
  }, [getAccessTokenSilently]);

  // useeffect to set token
  useEffect(() => {
    if (!isLoading) {
      retrieveAccessToken();
    }
  }, [isLoading, retrieveAccessToken]);

  //useEffect to calculate date time values on load of the page
  useEffect(() => {
    document.title = "Home";
    calculateDateTimeValues(selectedValue);
  }, []);

  const handleChange = (event) => {
    calculateDateTimeValues(event.target.value);
  };

  const { data: deviceGroupData, isFetched: isDeviceGroupDataFetched } =
    useQuery({
      queryKey: ["deviceGroups"],
      queryFn: ({ queryKey }) =>
        GetAllDeviceGroupsUseQuery(queryKey[0], authToken),
      enabled: !!authToken,
    });
  const {
    data: userInfo,
    isFetched: isUserInfoFetched,
    isFetching: isUserInfoFetching,
  } = useQuery({
    queryKey: ["userInfo", authToken],
    queryFn: () => GetUserUseQuery(authToken),
    enabled: !!authToken,
  });

  let userName = "";

  if (isUserInfoFetched) {
    userName =
      userInfo?.user?.name !== "" ? userInfo?.user?.name : user?.nickname;
  }

  // useQuery to fetch device group data from the server
  const deviceGroupList = isDeviceGroupDataFetched
  ? deviceGroupData
      ?.map((group) => group?.DGName)
      .sort()
  : [];


  useEffect(() => {
    if (selectedGroup === "" && deviceGroupList !== null && deviceGroupList !== undefined && deviceGroupList.length > 0) {
      setSelectedGroup(
        deviceGroupList.filter((dgname) => dgname == "Default Group")[0]
      );
    }
  }, [deviceGroupList]);

  //api options to fetch dashboard data from the server
  const apiOptions = useMemo(
    () =>
      apiBodyConstructorForDashboard(
        utcTimeStartString,
        utcTimeEndString,
        binSize,
        authToken,
        selectedGroup
      ),
    [utcTimeEndString, utcTimeStartString, binSize, authToken, selectedGroup]
  );

  const {
    data: dashboardData,
    isFetched: isDashboardDataFetched,
    isFetching: isDashboardDataFetching,
  } = useQuery({
    queryKey: ["dashboardData", apiOptions, selectedGroup, selectedValue],
    queryFn: ({ queryKey }) =>
      FetchDashboardStatistics(queryKey[0], queryKey[1]),
    enabled: !!authToken && selectedGroup !== "",
    onError: (error) => {
      console.error("Error fetching dashboard data", error.response.status);
    },
  });

  //generate data for the graphs

  const dataForGraphs = generateDataForGraphs(
    utcTimeStartString,
    utcTimeEndString,
    binSize,
    dashboardData
  );

  // generate configurations for the graphs
  const {
    hashRateGraphConfig,
    powerGraphConfig,
    tempuratureGraphConfig,
    uptimeGraphConfig,
  } = generateConfigForGraphs(dataForGraphs, binSize);

  let lineChartsData = [
    {
      heading: "Average Hashrate",
      subHeading: "in TH/s",
      chartOptions: hashRateGraphConfig,
      isExpanded: false,
      isExpansionRequired: true,
    },
    {
      heading: "Average Temperature",
      subHeading: "in ˚C",
      chartOptions: tempuratureGraphConfig,
      isExpanded: false,
      isExpansionRequired: true,
    },
    {
      heading: "Power",
      subHeading: "in kW",
      chartOptions: powerGraphConfig,
      isExpanded: false,
      isExpansionRequired: true,
    },
    // {
    //   heading: "Uptime",
    //   subHeading: "in minutes",
    //   chartOptions: uptimeGraphConfig,
    //   isExpanded: false,
    //   isExpansionRequired: true,
    // },
  ];

  //for the expansion mode, everytime user clicks on the expand button, the graph will be expanded
  // everytime user clicks on expand, we will pick the chart index and push it to expandedChart we form two arrays
  // one for the chart that needs to be expanded and one for all the other charts
  // we will then map over the two arrays and render the expanded chart first and then the other charts
  //on click of expand button we will have to expand the chart so sharing setExpandedChartIndex is sufficient

  useEffect(() => {
    if (expandedChartIndex !== -1) {
      const chartData = lineChartsData[expandedChartIndex];

      setExpandedChart([lineChartsData[expandedChartIndex]]);
      setOtherCharts(
        lineChartsData.filter((_, index) => index !== expandedChartIndex)
      );
      setIsExpandedGraphsModeActivated(true);
    }
    if (expandedChartIndex === -1) {
      setExpandedChart([]);
      setOtherCharts([]);
      setIsExpandedGraphsModeActivated(false);
    }
  }, [expandedChartIndex, dashboardData]);

if (validUser === 0) {
  return (
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#f9f9f9",
        overflowY: "auto",
        height: "auto",
      }}
    >
      {isUserInfoFetching ? (
        <Skeleton
          variant="rectangular"
          width={"auto"}
          animation={"wave"}
          height={200}
          sx={{ borderRadius: 2, backgroundColor: "#f3f3f3" }}
        />
      ) : (
        <Box
          className={"header-container"}
          display={"flex"}
          justifyContent="flex-start"
          flexDirection="column"
          gap={4}
          marginBottom={"1.5rem"}
        >
          <Box
            className={"title-box"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={3.5}
          >
            <H7TitleLarge>Welcome to FluxVision, {userName}!</H7TitleLarge>
            <BodyLarge>
              Monitor your mining performance with key metrics. Use the filters
              to explore data across different groups and time periods, and
              click any chart for an enlarged view.
            </BodyLarge>
          </Box>

          <Box
            className={"filter-box"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            // marginRight={"1.5rem"}
            flex={1}
            gap={2}
            maxHeight={"2rem"}
          >
            <H8TitleMedium> View data for: </H8TitleMedium>
            <FormControl>
              <Select
                size={"small"}
                value={selectedGroup ?? ""}
                onChange={(e) => setSelectedGroup(e.target.value)}
                style={{ width: "12rem", maxHeight: "2rem" }}
              >
                {deviceGroupList && deviceGroupList.length > 0 &&
                  deviceGroupList.map((group, index) => (
                    <MenuItem key={index} value={group}>
                      {group}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                size={"small"}
                style={{ width: "12rem", maxHeight: "2rem" }}
                value={selectedValue ?? ""}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {timeRanges.map((timefilter, index) => {
                  return (
                    <MenuItem key={index} value={timefilter.value}>
                      {timefilter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
      <Box width={"100%"}>
        <Grid2
          container
          spacing={isExpandedGraphsModeActivated ? 6 : 6}
          justifyContent="center"
          alignItems="center"
          padding={0}
        >
        {isDashboardDataFetched &&
          dashboardData?.status?.toLowerCase() === "ok" &&
          dashboardData?.msg?.length === 0 && (
            <Grid2 item xs={12}>
              <AuradineDataNotFoundState
                message={
                  selectedGroup === undefined
                    ? "No group selected !!"
                    : `No data found for ${selectedGroup} !!`
                }
              />
            </Grid2>
          )}
          {isDashboardDataFetched &&
            dashboardData?.status.toLowerCase() === "error" &&
            !Array.isArray(dashboardData?.msg) && (
              <Grid2 item xs={12}>
                <AuradineErrorState message={`Oops something went wrong!!`} />
              </Grid2>
            )}
          {!isExpandedGraphsModeActivated && (
            <>
              {isDashboardDataFetched &&
                dashboardData?.status?.toLowerCase() === "ok" &&
                dashboardData?.msg?.length > 0 &&
                lineChartsData?.map((chartData, index) => {
                  return (
                    <Grid2 item xs={6} key={index}>
                      <ChartCard elevation={0}>
                        <LineChart
                          key={index}
                          chartIndex={index}
                          heading={chartData.heading}
                          subHeading={chartData.subHeading}
                          lineChartOptions={chartData.chartOptions}
                          isExpanded={chartData.isExpanded}
                          isExpansionRequired={chartData.isExpansionRequired}
                          setExpandedChartIndex={setExpandedChartIndex}
                          isExpandedGraphsModeActivated={
                            isExpandedGraphsModeActivated
                          }
                        />
                      </ChartCard>
                    </Grid2>
                  );
                })}
              {isDashboardDataFetching &&
                !isDashboardDataFetched &&
                Array(lineChartsData.length)
                  .fill()
                  .map((_, index) => (
                    <Grid2 item xs={6} key={index}>
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width={"auto"}
                        animation={"wave"}
                        height={300}
                        sx={{ borderRadius: 2, backgroundColor: "#f3f3f3" }}
                      />
                    </Grid2>
                  ))}
            </>
          )}
          {isExpandedGraphsModeActivated && (
            <>
              {expandedChart.map((chartData, index) => {
                return (
                  <>
                    {isDashboardDataFetched &&
                      !isDashboardDataFetching &&
                      dashboardData?.status?.toLowerCase() === "ok" &&
                      dashboardData?.msg?.length > 0 && (
                        <Grid2 item xs={12} key={index}>
                          <ChartCard elevation={0}>
                            <LineChart
                              key={index}
                              chartIndex={lineChartsData.findIndex(
                                (chart) => chart.heading === chartData.heading
                              )}
                              heading={chartData.heading}
                              subHeading={chartData.subHeading}
                              lineChartOptions={chartData.chartOptions}
                              isExpanded={true}
                              isExpansionRequired={true}
                              setExpandedChartIndex={setExpandedChartIndex}
                              expandedChartIndex={expandedChartIndex}
                              isExpandedGraphsModeActivated={
                                isExpandedGraphsModeActivated
                              }
                            />
                          </ChartCard>
                        </Grid2>
                      )}
                    {isDashboardDataFetching && !isDashboardDataFetched && (
                      <Grid2 item xs={12}>
                        <Skeleton
                          variant="rectangular"
                          width={"auto"}
                          animation={"wave"}
                          height={300}
                          sx={{ borderRadius: 2, backgroundColor: "#f3f3f3" }}
                        />
                      </Grid2>
                    )}
                  </>
                );
              })}
              {otherCharts.map((chartData, index) => {
                return (
                  <>
                    {isDashboardDataFetched &&
                      !isDashboardDataFetching &&
                      dashboardData?.status.toLowerCase() === "ok" &&
                      dashboardData?.msg?.length > 0 && (
                        <Grid2 item xs={2.666} key={index}>
                          <MinimizedChartCard elevation={0} key={index}>
                            <LineChart
                              key={index}
                              chartIndex={lineChartsData.findIndex(
                                (chart) => chart.heading === chartData.heading
                              )}
                              heading={chartData.heading}
                              subHeading={chartData.subHeading}
                              lineChartOptions={chartData.chartOptions}
                              isExpanded={false}
                              isExpansionRequired={true}
                              setExpandedChartIndex={setExpandedChartIndex}
                              expandedChartIndex={expandedChartIndex}
                              isExpandedGraphsModeActivated={
                                isExpandedGraphsModeActivated
                              }
                            />
                          </MinimizedChartCard>
                        </Grid2>
                      )}
                    {isDashboardDataFetching && !isDashboardDataFetched && (
                      <Grid2 item xs={3}>
                        <Skeleton
                          variant="rectangular"
                          width={"auto"}
                          animation={"wave"}
                          height={200}
                          sx={{ borderRadius: 2, backgroundColor: "#f3f3f3" }}
                        />
                      </Grid2>
                    )}
                  </>
                );
              })}
            </>
          )}
        </Grid2>
        <Footer bgColor={"#f9f9f9"} />
      </Box>
    </Container>
  );
} else if (validUser !== 0 && validUser !== -1) {
  return(
    <div>
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} />
    </div>
  )
} else {
  return <PageLoading />
}
};

export default Home;
